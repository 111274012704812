'use client'

import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { Attachment as ExperimentalAttachment } from '@ai-sdk/ui-utils'
import { useUser } from '@clerk/nextjs'
import { Message } from 'ai'
import { useChat } from 'ai/react'
import { AnimatePresence, motion } from 'framer-motion'
import { nanoid } from 'nanoid'

import { useToast } from '@/app/components/ui/use-toast'
import { useConversations } from '@/app/context/ConversationContext'
import { handleError } from '@/app/lib/errors'
import { getLocationWithPermission } from '@/app/lib/location'
import { logger } from '@/app/lib/logging'
import {
  Attachment,
  ConciergeData,
  Conversation,
  LocationData,
  NexusState
} from '@/app/lib/types'
import { saveConversation } from '@/app/si/conversations'
import { useEnterSubmit } from './hooks/use-enter-submit'
import { useSmartAutoscroll } from './hooks/use-smart-autoscroll'
import {
  AIMessage,
  ConciergeMessage,
  FollowupMessage,
  ToolMessage,
  UserMessage
} from './messages'
import { OurSidebar } from './our-sidebar'
import { RequestInputForm } from './request-input-form'
import { Topbar } from './topbar'
import { ScrollArea } from './ui/scroll-area'
import ThemeImage from './ui/theme-image'

interface FollowupData {
  mood_analysis?: string[]
  resource_links?: string[]
  fun_fact?: string
  follow_up_questions?: string[]
}

export const STARTER_QUESTIONS = [
  'What makes Cora special?',
  "What makes Cora's AI approach heart-centered?",
  'How does Cora help with empathy?'
]

// Create a separate component for the search params logic
function SearchParamsHandler({
  hasHandledStart,
  setInput,
  router,
  formRef,
  setHasHandledStart
}: {
  hasHandledStart: boolean
  setInput: (input: string) => void
  router: any
  formRef: React.RefObject<HTMLFormElement>
  setHasHandledStart: (value: boolean) => void
}) {
  const searchParams = useSearchParams()

  useEffect(() => {
    if (!hasHandledStart && searchParams.has('start')) {
      // Set the initial question - welcoming for both new and existing users
      setInput('Help me better use and understand Cora')

      // Remove the start parameter from URL after form submission
      setTimeout(() => {
        const url = new URL(window.location.href)
        url.searchParams.delete('start')
        router.replace(url.pathname)
      }, 100) // Small delay to ensure form submission has started

      setHasHandledStart(true)
    }
  }, [hasHandledStart, searchParams, router, formRef, setInput, setHasHandledStart])

  return null
}

export default function Nexus() {
  const router = useRouter()
  const [hasHandledStart, setHasHandledStart] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { formRef, onKeyDown } = useEnterSubmit()
  const [conciergeLoading, setConciergeLoading] = useState(false)
  const [conciergeData, setConciergeData] = useState<ConciergeData | null>(null)
  const [conciergeStatus, setConciergeStatus] = useState<string | null>(null)
  const [aiStatus, setAiStatus] = useState<string>('')
  const [conversationId, setConversationId] = useState<string>(nanoid(12))
  const [messageAttachments, setMessageAttachments] = useState<any[]>([])
  const [anonymousUserId, setAnonymousUserId] = useState<string | undefined>(undefined)
  const [locationData, setLocationData] = useState<LocationData | null>(null)
  const { isSignedIn, user, isLoaded: isUserLoaded } = useUser()
  const { toast } = useToast()

  const [isGettingLocation, setIsGettingLocation] = useState(false)
  const [isWaitingForPermission, setIsWaitingForPermission] = useState(false)

  const [requestedModel, setRequestedModel] = useState('')
  const [processingMode, setProcessingMode] = useState<'swift' | 'balanced' | 'deep'>(
    'balanced'
  )
  const [manualRequestedModel, setManualRequestedModel] = useState<string | null>(null)

  const [showSearchHandler, setShowSearchHandler] = useState(false)

  const manageAnonymousUserId = useCallback(() => {
    if (isSignedIn && user) {
      localStorage.removeItem('anonymousUserId')
      logger.trace('Using logged-in user ID', {
        userEmail: user.emailAddresses[0].emailAddress
      })
      setAnonymousUserId(undefined)
    } else {
      const storedAnonymousUserId =
        localStorage.getItem('anonymousUserId') ?? `anon:${nanoid(12)}`
      localStorage.setItem('anonymousUserId', storedAnonymousUserId)
      logger.trace('Using anonymous user ID', storedAnonymousUserId)
      setAnonymousUserId(storedAnonymousUserId)
    }
  }, [isSignedIn, user])

  useEffect(() => {
    manageAnonymousUserId()
  }, [manageAnonymousUserId])

  /**
   * Calls the concierge API to enhance the user's input. Updates loading state and handles
   * errors. Returns the enhanced request data.
   */
  const callConcierge = async (
    messages: Message[],
    locationData: LocationData | null,
    conciergeOptions: { model: string },
    reqModel: string
  ): Promise<ConciergeData> => {
    setConciergeStatus('Understanding your request...')
    setConciergeLoading(true)
    logger.debug('Calling concierge with model:', { reqModel })
    try {
      const response = await fetch('/api/concierge', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          messages,
          attachments: messageAttachments.map(attachment => ({
            contentType: attachment.mimeType,
            name: attachment.name,
            url: attachment.cdnUrl
          })),
          user,
          anonymousUserId,
          conversationId,
          location: locationData,
          requestedModel: reqModel,
          conciergeOptions
        })
      })

      if (!response.ok) {
        throw new Error('Concierge request failed with status: ' + response.status)
      }

      let data: ConciergeData = await response.json()
      data.name = 'concierge'
      logger.trace('Concierge response received', { data })
      return data
    } catch (error) {
      handleError(error, 'Concierge')
      throw error
    } finally {
      setConciergeLoading(false)
      setConciergeStatus(null)
      logger.trace('Concierge call completed')
    }
  }

  /** Vercel's useChat hook that handles the chat functionality */
  const {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    append,
    stop,
    reload,
    data,
    setData
  } = useChat({
    api: '/api/agent_vercel',
    maxSteps: 5,
    sendExtraMessageFields: true,
    keepLastMessageOnError: true,
    body: () => ({
      user: user,
      conversationId: conversationId,
      anonymousUserId: anonymousUserId,
      location: locationData,
      requestedModel: requestedModel
    }),
    onError: (error: Error) => {
      handleError(error, 'LLM')
      setConciergeStatus(null)
      setAiStatus('')
    },
    onFinish: async (message: Message) => {
      // Skip followups and conversation saving in swift mode
      if (processingMode === 'swift') {
        setConciergeStatus(null)
        setAiStatus('')
        return
      }

      // Save the conversation
      let updatedMessages: Message[] = messages
      setMessages(prevMessages => {
        updatedMessages = [...prevMessages] as Message[]
        return updatedMessages
      })

      // Call followups API
      setConciergeStatus('Preparing followup suggestions...')
      setConciergeLoading(true)
      try {
        const followupMessage = await callFollowupsAPI(updatedMessages)
        if (followupMessage) {
          setMessages(prevMessages => [...prevMessages, followupMessage])
          // Update updatedMessages to include the followup message
          updatedMessages = [...updatedMessages, followupMessage] as Message[]
        }
      } catch (error) {
        logger.error('Error calling followups API', { error })
      } finally {
        setConciergeLoading(false)
        setConciergeStatus(null)
      }

      // Save conversation
      try {
        await saveMemoryConversation(updatedMessages)
      } catch (error) {
        logger.error('Error saving conversation', { error })
      }

      setConciergeStatus(null)
      setAiStatus('')
    }
  })

  const seenMessages = useRef(new Set<string>())

  // Whenever data changes, show new unique toasts
  useEffect(() => {
    if (!data?.length) return

    data.forEach((item: any) => {
      if ('type' in item && typeof item.message === 'string') {
        const key = `${item.type as string}-${item.message}`
        if (!seenMessages.current.has(key)) {
          seenMessages.current.add(key)
          logger.debug('Showing toast', { item })
          toast({
            title: item.type === 'error' ? 'Error' : 'Status',
            description: item.message,
            duration: item.type === 'error' ? undefined : 5000, // Set duration based on type
            variant: item.type === 'error' ? 'destructive' : undefined
          })
        }
      }
    })

    setData([]) // Clear processed data
  }, [data, toast, setData])

  useEffect(() => {
    if (isLoading) {
      setAiStatus(
        messages.length > 0 && messages[messages.length - 1].role === 'assistant'
          ? 'Typing...'
          : 'Thinking...'
      )
    } else {
      setAiStatus('')
    }
  }, [isLoading, messages])

  // setConciergeData does not always execute, so we store conciergeResult outside of the function
  let conciergeResult: ConciergeData | null = null

  /**
   * Handles the form submission for user input. Enhances the user's input using the concierge
   * API, if available. Appends both user and system messages to the chat. Resets the input
   * field after submission.
   */
  const ourHandleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input.trim() === '') return

    // Store the input before clearing
    const userRequest = input
    const originalAttachments = [...messageAttachments]

    // Clear input early
    setInput('')
    setMessageAttachments([])

    try {
      await processInput(userRequest, originalAttachments)
    } catch (error) {
      // Restore the input if something goes wrong
      setInput(userRequest)
      setMessageAttachments(originalAttachments)
      handleError(error, 'Message Processing')
    }
  }

  const processInput = async (userRequest: string, messageAttachments: any[]) => {
    // Create ExperimentalAttachment from the messageAttachments array
    const experimentalAttachments: ExperimentalAttachment[] = messageAttachments.map(
      (attachment: Attachment) => ({
        name: attachment.name,
        url: attachment.cdnUrl,
        contentType: attachment.mimeType,
        size: attachment.size
      })
    )

    const userMessage: Message = {
      id: nanoid(),
      createdAt: new Date(),
      role: 'user',
      content: userRequest,
      experimental_attachments: experimentalAttachments
    }

    let currentLocationData = locationData

    // NEW: Determine the final model – use the manual override if provided, otherwise the slider's mapping.
    const finalRequestedModel =
      manualRequestedModel ??
      (() => {
        switch (processingMode) {
          case 'swift':
            return 'cora:fast'
          case 'deep':
            return 'cora:reasoning'
          case 'balanced':
          default:
            return 'auto'
        }
      })()

    // For swift mode, we immediately append the user message.
    if (processingMode === 'swift') {
      setRequestedModel(finalRequestedModel)
      append(userMessage, {
        body: {
          user: user,
          conversationId: conversationId,
          anonymousUserId: anonymousUserId,
          location: currentLocationData,
          requestedModel: finalRequestedModel
        }
      })
      return
    }

    // Otherwise, update the state with the final model.
    setRequestedModel(finalRequestedModel)
    // Update messages with the user input first.
    const updatedMessages = [...messages, userMessage]
    setMessages(updatedMessages)

    try {
      // Select concierge model based on processing mode
      const conciergeOptions = {
        model: processingMode === 'deep' ? 'cora:structured' : 'cora:structured-fast'
      }

      conciergeResult = await callConcierge(
        updatedMessages,
        currentLocationData,
        conciergeOptions,
        finalRequestedModel
      )
      setConciergeData(conciergeResult)
      logger.trace('Concierge data set', { conciergeResult, processingMode })

      // Get location if needed...
      if (conciergeResult.requiresLocation && !currentLocationData) {
        setIsGettingLocation(true)
        try {
          currentLocationData = await getLocationWithPermission(setIsWaitingForPermission)
        } catch (error) {
          logger.error('Error getting location:', error)
        } finally {
          setIsGettingLocation(false)
          setLocationData(currentLocationData)
        }
      }

      const conciergeMessage: Message = {
        id: nanoid(),
        createdAt: new Date(),
        role: 'data',
        content: conciergeResult.enhancedRequest,
        data: JSON.parse(JSON.stringify(conciergeResult))
      }

      // Append the concierge message using the final model
      append(conciergeMessage, {
        body: {
          conciergeData: conciergeResult,
          user: user,
          conversationId: conversationId,
          anonymousUserId: anonymousUserId,
          location: currentLocationData,
          requestedModel: finalRequestedModel
        }
      })
    } catch (error) {
      logger.error('Concierge failed', { error, processingMode })
      toast({
        title: 'Taking a Different Path',
        description:
          "I'm having trouble with some of my enhanced concierge features, but don't worry - we can continue our conversation directly! 💫",
        duration: 4000
      })

      // Fallback: continue the conversation without enhanced concierge.
      append(userMessage, {
        body: {
          user: user,
          conversationId: conversationId,
          anonymousUserId: anonymousUserId,
          location: currentLocationData,
          requestedModel: finalRequestedModel
        }
      })
    }
  }

  /**
   * Smart auto-scrolling for the chat interface. Auto-scrolls to new messages, pauses on user
   * interaction. Resumes scrolling when user returns to bottom.
   */
  const {
    scrollRef,
    messagesEndRef,
    isAtBottom,
    isUserScrolling,
    scrollToBottom,
    setIsUserScrolling
  } = useSmartAutoscroll()

  useEffect(() => {
    if (isAtBottom && !isUserScrolling) {
      scrollToBottom()
    }
  }, [messages, isAtBottom, isUserScrolling, scrollToBottom])

  const handleNewConversation = useCallback(() => {
    setMessages([])
    setConversationId(nanoid(12))
    setConciergeData(null)
    setConciergeStatus(null)
    seenMessages.current.clear() // Clear the message history
  }, [setMessages, setConversationId, setConciergeData, setConciergeStatus])

  const nexusState: NexusState = {
    // Chat-related (from useChat)
    messages,
    setMessages,
    input,
    setInput,
    append,
    isLoading,
    stop,
    reload,

    // UI and form references
    formRef,
    inputRef,
    onKeyDown,

    // User and session
    user,
    isSignedIn,
    isUserLoaded,
    anonymousUserId,

    // Conversation management
    conversationId,
    setConversationId,
    handleNewConversation,

    // Attachments
    messageAttachments,
    setMessageAttachments,

    // Concierge-related
    conciergeData,
    setConciergeData,
    conciergeLoading,
    conciergeStatus,
    setConciergeStatus,

    // AI status
    aiStatus,
    setAiStatus,

    // Model selection
    requestedModel,
    setRequestedModel,

    // Processing mode for speed vs quality slider
    processingMode,
    setProcessingMode,

    // NEW: Manual override values for requested model (dropdown)
    manualRequestedModel,
    setManualRequestedModel
  }

  const { conversations, setConversations } = useConversations()

  const saveMemoryConversation = async (messages: Message[]) => {
    try {
      if (!anonymousUserId && !user) {
        logger.error('No user ID found when trying to save conversation')
        return
      }

      // Find existing conversation
      const existingConversation = conversations.find(c => c.id === conversationId)

      const conversationData: Omit<Conversation, 'createdAt' | 'updatedAt'> = {
        id: conversationId,
        messages,
        userId: anonymousUserId, // will only be set for anonymous users, or undefined for logged in users for security
        // Only set title if this is a new conversation
        title:
          existingConversation?.title ||
          conciergeData?.conversationTitle ||
          conciergeResult?.conversationTitle ||
          'Untitled Conversation'
      }

      logger.debug('Saving conversation', {
        conversationId: conversationData.id,
        messageCount: messages.length,
        userId: anonymousUserId ? anonymousUserId : 'authenticated',
        isNew: !existingConversation
      })

      // Check if this conversation is already in the list
      const isNewConversation = !conversations.some(c => c.id === conversationId)

      // Optimistically update the UI
      setConversations(prevConversations => {
        const existingIndex = prevConversations.findIndex(c => c.id === conversationId)
        const updatedConversation: Conversation = {
          id: conversationData.id,
          title: conversationData.title,
          userId: conversationData.userId,
          messages: conversationData.messages,
          createdAt: isNewConversation
            ? new Date()
            : prevConversations[existingIndex]?.createdAt || new Date(),
          updatedAt: new Date()
        }

        if (existingIndex !== -1) {
          // Update existing conversation but preserve its title
          const updatedConversations = [...prevConversations]
          updatedConversations[existingIndex] = {
            ...updatedConversation,
            title: prevConversations[existingIndex].title // Preserve existing title
          }
          return [
            updatedConversation,
            ...updatedConversations.filter(c => c.id !== conversationId)
          ]
        } else {
          // Add new conversation
          return [updatedConversation, ...prevConversations]
        }
      })

      // Save to Redis
      const { success } = await saveConversation(conversationData, anonymousUserId)

      if (success) {
        logger.trace(`Conversation ${isNewConversation ? 'added to' : 'updated in'} the list`)
      } else {
        // If save fails, log the error and notify the user
        logger.error('Failed to save conversation to Redis')
        toast({
          title: 'Sync Delayed',
          description:
            "We're having trouble saving your progress right now, but don't worry - we'll keep trying in the background.",
          duration: 5000
        })
      }
    } catch (error) {
      handleError(error, 'Conversation Saving')
      // Notify the user of the error, but don't revert UI changes
      toast({
        title: 'Sync Issue',
        description:
          "We're experiencing some difficulties saving your journey, but you can keep chatting, and we'll keep trying.",
        duration: 5000
      })
    }
  }

  const renderMessage = (m: Message) => {
    let messageContent
    switch (m.role) {
      case 'user':
        messageContent = <UserMessage message={m} nexusState={nexusState} />
        break
      case 'data':
        if (m.data && typeof m.data === 'object' && 'name' in m.data) {
          if (m.data.name === 'followup') {
            messageContent = <FollowupMessage message={m} setInput={setInput} />
          } else if (m.data.name === 'concierge') {
            messageContent = (
              <ConciergeMessage
                message={m}
                conciergeData={m.data as unknown as ConciergeData}
                originalQuery={input}
                nexusState={nexusState}
              />
            )
          }
        }
        break
      default: // assistant
        if (m.toolInvocations && m.toolInvocations.length > 0) {
          messageContent = <ToolMessage message={m} nexusState={nexusState} />
        } else {
          messageContent = <AIMessage message={m} nexusState={nexusState} />
        }
    }

    return (
      <motion.div
        key={m.id}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        style={{
          transformOrigin: 'center left'
        }}
      >
        {messageContent}
      </motion.div>
    )
  }

  const logoVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
      y: 20,
      filter: 'blur(10px)'
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      filter: 'blur(0px)',
      transition: {
        duration: 1.5,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      y: -20,
      filter: 'blur(10px)',
      transition: {
        duration: 1.2,
        ease: 'easeIn'
      }
    }
  }
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!hasHandledStart && mounted && !isLoading && formRef.current) {
      setShowSearchHandler(true)
    }
  }, [hasHandledStart, isLoading, mounted, formRef])

  if (!mounted) {
    return null // or a loading spinner
  }

  return (
    <div className="flex h-[100dvh] w-full flex-col md:flex-row">
      <OurSidebar nexusState={nexusState} />
      <div className="flex h-full w-full flex-col">
        {showSearchHandler && (
          <Suspense fallback={null}>
            <SearchParamsHandler
              hasHandledStart={hasHandledStart}
              setInput={setInput}
              router={router}
              formRef={formRef}
              setHasHandledStart={setHasHandledStart}
            />
          </Suspense>
        )}
        <div id="topbar_holder" className="flex-shrink-0">
          <Topbar nexusState={nexusState} />
        </div>
        <div
          id="nexus"
          className="bg-light dark:bg-dark flex flex-grow flex-col overflow-hidden"
        >
          <div id="chat-message-container" className="flex-grow overflow-hidden">
            <ScrollArea
              ref={scrollRef}
              className="h-full"
              onMouseDown={() => setIsUserScrolling(true)}
              onWheel={() => setIsUserScrolling(true)}
            >
              <AnimatePresence>
                {messages.length === 0 && (
                  <motion.div
                    className="absolute inset-0 flex items-center justify-center pt-4"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={logoVariants}
                  >
                    <ThemeImage
                      lightSrc="/img/bubbletar-cora-purple.svg"
                      darkSrc="/img/bubbletar-cora-white.svg"
                      alt="Cora Logo"
                      width={100}
                      height={100}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              {messages.length > 0 && (
                <div className="mx-auto w-full max-w-4xl px-4 sm:px-6 pt-4">
                  <AnimatePresence>{messages.map(renderMessage)}</AnimatePresence>
                </div>
              )}
              <div ref={messagesEndRef} className="h-24" />
            </ScrollArea>
          </div>
          {/* Chat input form */}
          <div className="mx-auto w-full max-w-4xl flex-shrink-0 px-4 pb-4 sm:px-6 sm:pb-6">
            <div className="overflow-hidden rounded-xl">
              <RequestInputForm
                nexusState={nexusState}
                onSubmit={ourHandleSubmit}
                followUpQuestions={
                  messages.length === 0
                    ? STARTER_QUESTIONS
                    : (
                        messages
                          .filter(
                            m =>
                              // ZOMG typescript
                              m.role === 'data' &&
                              typeof m.data === 'object' &&
                              m.data &&
                              'name' in m.data &&
                              m.data.name === 'followup'
                          )
                          .slice(-1)[0]?.data as FollowupData
                      )?.follow_up_questions || []
                }
              />
            </div>
          </div>
        </div>
      </div>
      {(isGettingLocation || isWaitingForPermission) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-w-md rounded-lg bg-white p-6">
            {isWaitingForPermission ? (
              <>
                <h3 className="mb-2 text-lg font-semibold">
                  Enhance Your Experience with Location
                </h3>
                <p className="mb-4">
                  With your location, I can provide more personalized assistance. Your privacy
                  is my priority - I will only use this to tailor our current conversation.
                </p>
                <p className="text-sm text-gray-600">
                  Please allow location access in your browser to continue.
                </p>
              </>
            ) : isGettingLocation ? (
              <p className="text-sm text-gray-600">Finding your location...</p>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

const callFollowupsAPI = async (messages: Message[]): Promise<Message | null> => {
  try {
    const response = await fetch('/api/followups', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ messages })
    })

    if (!response.ok) {
      throw new Error('Followups request failed with status: ' + response.status)
    }

    const data = await response.json()
    return {
      id: nanoid(),
      createdAt: new Date(),
      role: 'data',
      content: 'Follow up data to enhance the conversation',
      data: {
        name: 'followup' as const,
        ...data
      }
    }
  } catch (error) {
    handleError(error, 'Followups')
    return null
  }
}
