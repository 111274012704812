/**
 * Model Configuration and Selection System
 *
 * This file manages the configuration, selection, and interaction with various language models
 * (LLMs) across different providers (Anthropic, OpenAI, Groq, etc.). It handles:
 *
 * - Provider configuration and registration
 * - Model selection logic and fallback strategies
 * - Model information parsing and formatting
 * - UI-related model configurations
 */

import { anthropic } from '@ai-sdk/anthropic'
import { google } from '@ai-sdk/google'
import { groq } from '@ai-sdk/groq'
import { createOpenAI, openai } from '@ai-sdk/openai'
import { createOpenRouter } from '@openrouter/ai-sdk-provider'
import {
  experimental_createProviderRegistry as createProviderRegistry,
  customProvider,
  Message
} from 'ai'

import { logger } from '@/app/lib/logging'

//=============================================================================
// Types & Interfaces
//=============================================================================

/** Represents the different response strategies available for language models. */
type ResponseStrategy = 'thoughtful' | 'reasoning' | 'realtime' | 'creative' | 'fast'

/** Configuration for a model selection option in the UI */
export type ModelOption = {
  value: string // e.g., 'auto' or 'cora:anthropic'
  label: string // e.g., 'Auto (Let Cora choose)' or 'Anthropic Claude 3.7'
  logo: string // e.g., '/img/cora-logo.svg'
}

/** Type for provider model IDs in our registry */
export type ProviderModelId =
  | `anthropic:${string}`
  | `openai:${string}`
  | `groq:${string}`
  | `cora:${string}`
  | `google:${string}`
  | `openrouter:${string}`

export interface ModelCapabilities {
  tools: boolean
  vision: boolean
  attachments: boolean
}

//=============================================================================
// Provider Configuration
//=============================================================================

const openrouter = createOpenAI({
  baseURL: 'https://openrouter.ai/api/v1',
  apiKey: process.env.OPENROUTER_API_KEY
})

/**
 * Creates an OpenRouter instance with fallback configuration
 * https://openrouter.ai/docs/model-routing
 */
export function createOpenRouterWithFallback(modelId: string, fallbackModels: string[]) {
  const provider = createOpenRouter({
    apiKey: process.env.OPENROUTER_API_KEY!,
    extraBody: {
      models: [modelId, ...fallbackModels],
      route: 'fallback'
    }
  })
  return provider(modelId) // Call the provider with modelId to get a LanguageModelV1
}

export const cora = customProvider({
  languageModels: {
    // Provider-specific models
    anthropic: anthropic('claude-3-7-sonnet-latest', {
      cacheControl: true
    }),
    'anthropic-fast': anthropic('claude-3-5-haiku-latest', { cacheControl: true }),

    openai: openrouter('openai/gpt-4o'),
    'openai-reasoning': openrouter('openai/o3-mini-high'),

    groq: groq('llama-3.1-70b-versatile'),
    'groq-fast': groq('llama-3.1-8b-instant'),

    gemini: openrouter('google/gemini-2.0-flash-thinking-exp:free'),

    perplexity: openrouter('perplexity/sonar-pro'),

    // Response Strategies (used by concierge)
    thoughtful: anthropic('claude-3-7-sonnet-latest', {
      cacheControl: true
    }),

    reasoning: createOpenRouterWithFallback('openai/o3-mini-high', [
      'anthropic/claude-3-7-sonnet'
    ]),

    realtime: createOpenRouterWithFallback('perplexity/sonar-pro', [
      'openai/gpt-4o-search-preview'
    ]),

    creative: anthropic('claude-3-7-sonnet-latest', {
      cacheControl: true
    }),

    // Add fast strategy model
    fast: anthropic('claude-3-5-haiku-latest', { cacheControl: true }),

    // Capabilities (preserved from original)
    structured: anthropic('claude-3-7-sonnet-latest'),
    'structured-fast': createOpenRouterWithFallback('anthropic/claude-3-5-haiku-latest', [
      'openai/gpt-4o-mini'
    ]),
    'structured-lightning': groq('llama3-groq-70b-8192-tool-use-preview'),

    // Special capabilities
    attachments: anthropic('claude-3-7-sonnet-latest', { cacheControl: true }),
    default: anthropic('claude-3-7-sonnet-latest', { cacheControl: true }),

    // Add DeepSeek model
    deepseek: openrouter('deepseek/deepseek-r1-distill-llama-70b')
  }
})

export const registry = createProviderRegistry({
  anthropic,
  cora,
  google,
  groq,
  openai,
  openrouter
})

/**
 * Helper function to safely access language models from the registry
 *
 * @param modelId The model ID to retrieve
 * @returns The language model instance
 */
export function getLanguageModel(modelId: string) {
  return registry.languageModel(modelId as ProviderModelId)
}

//=============================================================================
// Model Information & Parsing
//=============================================================================

export const providerLogos: { [key: string]: string } = {
  anthropic: '/img/anthropic-logo.svg',
  google: '/img/google-logo.svg',
  groq: '/img/groq-logo.svg',
  openai: '/img/openai-logo.svg',
  perplexity: '/img/perplexity-logo.svg',
  deepseek: '/img/deepseek-logo.svg'
}

/**
 * Parses a model identifier into its component parts
 *
 * @param input - Model identifier string or LLM object
 * @returns Object containing model and provider information
 */
function parseModelAndProvider(input: string | any): { model: string; provider: string } {
  // Case 1: Input is a string like "cora:anthropic" or "openai/gpt-4"
  if (typeof input === 'string') {
    if (input.includes(':')) {
      // Handle cora:model format
      const [_, model] = input.split(':')
      return { model, provider: model.split('-')[0] }
    }
    if (input.includes('/')) {
      // Handle provider/model format (openrouter style)
      const [provider, model] = input.split('/')
      return { model, provider }
    }
    // Handle plain model name
    return {
      model: input,
      provider: input.split('-')[0]
    }
  }

  // Case 2: Input is an LLM object
  if (input.modelId) {
    if (input.modelId.includes('/')) {
      // Openrouter model
      const [provider, model] = input.modelId.split('/')
      return { model, provider }
    }
    if (input.config?.provider) {
      // format is $provider.chat or $provider.generative-ai
      const provider = input.config.provider.split('.')[0]
      return { model: input.modelId, provider }
    }
  }

  throw new Error('Unable to determine model info')
}

/**
 * Retrieves human-readable information about a language model
 *
 * @param llm - The language model to get information for
 */
export function getModelInfo(llm: any): {
  humanProvider: string
  humanModel: string
  providerLogo: string
} {
  const { model, provider } = parseModelAndProvider(llm)

  const humanProviderNames: { [key: string]: string } = {
    anthropic: 'Anthropic',
    groq: 'Groq',
    openai: 'OpenAI',
    google: 'Google',
    perplexity: 'Perplexity',
    deepseek: 'DeepSeek'
  }

  const humanModelNames: { [key: string]: string } = {
    // Anthropic
    'claude-instant-1.1': 'Claude (fast)',
    'claude-3-7-sonnet-latest': 'Claude 3.7 Sonnet (thoughtful)',
    'claude-3-5-haiku-latest': 'Claude 3.5 Haiku (fast)',
    // OpenAI
    'gpt-4o': 'GPT 4o (thoughtful)',
    'gpt-4o-mini': 'GPT 4o mini (fast)',
    'o1-preview': 'O1 (reasoning)',
    'o3-mini-high': 'O3 mini high (advanced reasoning)',
    // Google
    'gemini-1.5-pro': 'Gemini 1.5 Pro (thoughtful)',
    'gemini-1.5-flash': 'Gemini 1.5 Flash (fast)',
    // Groq models
    'llama-3.1-70b-versatile': 'LLaMA 3.1 70B (versatile)',
    'llama-3.1-8b-instant': 'LLaMA 3.1 8B (fast)',
    'gemma2-9b-it': 'Gemma 2 9B',
    'mixtral-8x7b-32768': 'Mixtral 8x7B'
  }

  const humanProvider =
    humanProviderNames[provider] || provider.charAt(0).toUpperCase() + provider.slice(1)

  const humanModel =
    humanModelNames[model] ||
    model
      .split('-')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

  return {
    humanProvider,
    humanModel,
    providerLogo: providerLogos[provider] || ''
  }
}

/**
 * Returns a human-readable label for a language model
 *
 * @param llm - The language model to get a label for
 */
export function getModelLabel(llm: any): string {
  const { humanModel, humanProvider } = getModelInfo(llm)
  return `${humanModel} via ${humanProvider}`
}

//=============================================================================
// Model Selection & Configuration
//=============================================================================

/** Returns the list of available models for UI selection */
export function getAvailableModels(): ModelOption[] {
  return [
    {
      value: 'auto',
      label: 'Auto (Let Cora choose)',
      logo: '/img/brain.svg'
    },
    {
      value: 'cora:fast',
      label: 'Claude 3.5 Haiku (Swift)',
      logo: '/img/anthropic-logo.svg'
    },
    {
      value: 'cora:anthropic',
      label: 'Claude 3.7 Sonnet (Balanced)',
      logo: '/img/anthropic-logo.svg'
    },
    {
      value: 'cora:reasoning',
      label: 'O3 Mini High (Advanced Reasoning)',
      logo: '/img/openai-logo.svg'
    },
    {
      value: 'cora:openai',
      label: 'GPT-4o (Well-Rounded)',
      logo: '/img/openai-logo.svg'
    },
    {
      value: 'cora:perplexity',
      label: 'Perplexity (Real-Time Knowledge)',
      logo: '/img/perplexity-logo.svg'
    }
  ]
}

/**
 * Attempts to validate and use a model, falling back to default if needed
 *
 * @param modelId - The model identifier to validate
 * @param context - Context for logging (e.g., 'strategy' or 'selected')
 * @returns String - Valid model identifier
 */
function validateAndGetModel(modelId: string, context: string): string {
  try {
    // Use our helper function instead of direct casting
    getLanguageModel(modelId)
    return modelId
  } catch (error) {
    // Log the error but don't expose internal details
    logger.warn(`${context} model not found in registry, using default`, {
      modelId,
      error
    })

    // Add provider context to logging if available
    const providerHint = modelId.includes(':') ? ` (${modelId.split(':')[1]})` : ''

    logger.info(
      `Falling back to default model - ${context} model${providerHint} not available`
    )
    return 'cora:default'
  }
}

/**
 * Determines the appropriate model to use based on context and strategy
 *
 * @param messages - The conversation messages
 * @param responseStrategy - The desired response strategy
 * @param selectedModel - Optional explicitly selected model
 * @returns String - The model identifier to use
 */
export function determineResponseModel(
  messages: Message[],
  responseStrategy: ResponseStrategy,
  selectedModel?: string
): string {
  // Check for attachments first
  const hasAttachments = messages.some(msg => msg.experimental_attachments?.length)

  if (hasAttachments) {
    // If using reasoning model with attachments, fall back to default
    if (selectedModel === 'cora:openai-reasoning') {
      logger.info('Falling back to default model for attachments with reasoning model')
      return 'cora:default'
    }
    return 'cora:attachments'
  }

  // If no model selected or auto, use response strategy
  if (!selectedModel || selectedModel === 'auto') {
    const strategyModel = `cora:${responseStrategy}`
    return validateAndGetModel(strategyModel, 'strategy')
  }

  // Use explicitly selected model with fallback
  return validateAndGetModel(selectedModel, 'selected')
}

// Add a map of model capabilities
const MODEL_CAPABILITIES: Record<string, Partial<ModelCapabilities>> = {
  // Models WITHOUT tool support
  perplexity: { tools: false },
  realtime: { tools: false },
  reasoning: { tools: false },
  deepseek: { tools: false },
  'o1-preview': { tools: false },

  // Default capabilities (applied when not specified)
  default: { tools: true, vision: true, attachments: true }
}

/**
 * Checks if a model supports a specific capability
 *
 * @param modelId - Full model identifier (e.g., 'cora:anthropic')
 * @param capability - Capability to check for
 * @returns Boolean indicating if the model supports the capability
 */
export function hasModelCapability(
  modelId: string,
  capability: keyof ModelCapabilities
): boolean {
  // Extract model name from the ID
  let modelName: string

  if (modelId.includes(':')) {
    modelName = modelId.split(':')[1] // For 'cora:anthropic' -> 'anthropic'
  } else if (modelId.includes('/')) {
    modelName = modelId.split('/')[0] // For 'openai/gpt-4o' -> 'openai'
  } else {
    modelName = modelId
  }

  // Check if this specific model has defined capabilities
  if (
    MODEL_CAPABILITIES[modelName] &&
    typeof MODEL_CAPABILITIES[modelName][capability] !== 'undefined'
  ) {
    return MODEL_CAPABILITIES[modelName][capability] as boolean
  }

  // Fall back to default capabilities
  return MODEL_CAPABILITIES.default[capability] as boolean
}

// Add this specific helper for tool support
export function supportsTools(modelId: string): boolean {
  return hasModelCapability(modelId, 'tools')
}
