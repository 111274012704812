import * as React from 'react'

const MOBILE_BREAKPOINT = 768

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    // Check once on mount and don't update on resize
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
  }, [])

  return !!isMobile
}
