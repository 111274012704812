'use client'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { UploadCtxProvider } from '@uploadcare/react-uploader'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Brain,
  Check,
  Loader,
  Paperclip,
  Scale,
  Send,
  Square,
  Target,
  Trash2,
  Zap
} from 'lucide-react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { RingLoader } from 'react-spinners'
import Textarea from 'react-textarea-autosize'

import { AttachmentIcon } from '@/app/components/attachment-icon'
import { useIsMobile } from '@/app/components/hooks/use-mobile'
import { STARTER_QUESTIONS } from '@/app/components/nexus'
import { Button } from '@/app/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/app/components/ui/dropdown-menu'
import { Slider } from '@/app/components/ui/slider'
import { Switch } from '@/app/components/ui/switch'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/app/components/ui/tooltip'
import { useToast } from '@/app/components/ui/use-toast'
import { logger } from '@/app/lib/logging'
import { NexusState } from '@/app/lib/types'
import { uploadcareClientConfig } from '@/app/lib/uploadcare'
import { cn } from '@/app/lib/utils'
import { saveAttachment } from '@/app/si/attachments'
import { getAvailableModels } from '@/app/si/models'

import '@uploadcare/react-uploader/core.css'

// Load the FileUploaderRegular component dynamically to avoid server-side hydration errors
const FileUploaderRegular = dynamic(
  () => import('@uploadcare/react-uploader').then(mod => mod.FileUploaderRegular),
  { ssr: false }
)

// React complains about the uc-upload-ctx-provider element not being defined
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'uc-upload-ctx-provider': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

// We accept what Claude supports
// https://support.anthropic.com/en/articles/8241126-what-kinds-of-documents-can-i-upload-to-claude-ai
const acceptedFileTypes = {
  // Documents
  'application/pdf': [],
  'text/plain': [],
  'text/csv': [],
  'text/markdown': ['.md', '.markdown'],
  'text/x-markdown': ['.md', '.markdown'],
  // Images
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
  'image/webp': [],
  // Other
  'text/html': [],
  'application/json': [],
  'application/xml': []
}

// Add file size limits based on Claude's documentation
const MAX_FILE_SIZE = 30 * 1024 * 1024 // 30MB
const MAX_FILES = 5

// Add these components at the top of the file, after imports
interface SuggestedQuestionsProps {
  questions: string[]
  onSelect: (question: string) => void
  label: string
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = ({
  questions,
  onSelect,
  label
}) => {
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="mb-2"
    >
      <div className="flex flex-col gap-1.5 sm:flex-row sm:gap-2">
        {questions.slice(0, 3).map((question, index) => (
          <button
            key={index}
            onClick={() => onSelect(question)}
            className="rounded-lg bg-gray-100 px-3 py-2 text-left text-xs text-gray-900 transition-all duration-200 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 sm:flex-1 sm:px-4 sm:py-3"
          >
            <span className="line-clamp-2">{question}</span>
          </button>
        ))}
      </div>
    </motion.div>
  )
}

interface RequestInputFormProps {
  nexusState: NexusState
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  followUpQuestions?: string[]
  selectedModel?: string
  onModelChange?: (model: string) => void
}

// Add the animation styles
const dropdownAnimation = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
  transition: { duration: 0.1 }
}

// Add these styles to improve the dropdown appearance
const dropdownStyles = {
  content: 'z-50 min-w-[220px] rounded-md bg-white p-1 shadow-md dark:bg-gray-800',
  item: 'relative flex cursor-default select-none items-center rounded-sm px-2 py-2 text-sm outline-none transition-colors hover:bg-gray-100 dark:hover:bg-gray-700',
  separator: 'my-1 h-px bg-gray-200 dark:bg-gray-700',
  activeItem: 'bg-gray-100 dark:bg-gray-700'
}

export function RequestInputForm({
  nexusState,
  onSubmit,
  followUpQuestions = [],
  selectedModel,
  onModelChange
}: RequestInputFormProps) {
  const {
    input,
    setInput,
    isLoading,
    stop,
    inputRef,
    formRef,
    onKeyDown,
    anonymousUserId,
    user,
    messageAttachments,
    setMessageAttachments,
    conciergeStatus,
    conciergeLoading,
    aiStatus,
    conciergeData,
    processingMode,
    setProcessingMode,
    setRequestedModel,
    setManualRequestedModel,
    requestedModel,
    messages
  } = nexusState

  const ctxProviderRef = useRef<InstanceType<UploadCtxProvider>>(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [isUploadcareDialogOpen, setIsUploadcareDialogOpen] = React.useState(false)

  const { toast } = useToast()

  const [showStarterQuestions, setShowStarterQuestions] = React.useState(true)
  const [showFollowUpQuestions, setShowFollowUpQuestions] = React.useState(false)

  const isMobile = useIsMobile()
  const [enterToSubmit, setEnterToSubmit] = useState(() => {
    // Default to true on desktop, false on mobile
    const stored = localStorage.getItem('enterToSubmit')
    return stored !== null ? stored === 'true' : !isMobile
  })

  const [controlsExpanded, setControlsExpanded] = useState(true)
  const hasMessages = messages.length > 0

  useEffect(() => {
    localStorage.setItem('enterToSubmit', enterToSubmit.toString())
  }, [enterToSubmit])

  React.useEffect(() => {
    // Handle starter questions visibility
    setShowStarterQuestions(
      input.length === 0 &&
        !isLoading &&
        !conciergeLoading &&
        messageAttachments.length === 0 &&
        nexusState.messages.length === 0
    )
  }, [
    input,
    isLoading,
    conciergeLoading,
    messageAttachments.length,
    nexusState.messages.length
  ])

  React.useEffect(() => {
    // Handle follow-up questions visibility
    setShowFollowUpQuestions(
      input.length === 0 &&
        !isLoading &&
        !conciergeLoading &&
        followUpQuestions.length > 0 &&
        nexusState.messages.length > 0
    )
  }, [input, isLoading, conciergeLoading, followUpQuestions, nexusState.messages.length])

  // Define slider mapping for processing mode: 0 -> 'swift', 1 -> 'balanced', 2 -> 'deep'
  const sliderMapping = ['swift', 'balanced', 'deep'] as const
  const sliderValue = sliderMapping.indexOf(processingMode)
  const handleSliderChange = (value: number[]) => {
    const newMode = sliderMapping[value[0]]
    setProcessingMode(newMode)
    setManualRequestedModel(null)
    const modelMapping = {
      swift: 'cora:fast',
      balanced: 'auto',
      deep: 'cora:reasoning'
    }
    setRequestedModel(modelMapping[newMode])
  }

  const handleFileUpload = useCallback(
    async (file: File) => {
      if (messageAttachments.length >= MAX_FILES) {
        toast({
          title: 'Too many files',
          description: 'Maximum of 5 files allowed per message.',
          duration: 5000
        })
        return
      }

      const uploadCareAPI = ctxProviderRef.current?.getAPI()
      if (file && uploadCareAPI) {
        setIsUploading(true)
        try {
          await uploadCareAPI.addFileFromObject(file, { fileName: file.name })
        } catch (error) {
          logger.error('Error uploading file:', error)
          setIsUploading(false)
          toast({
            title: "Let's try that again",
            description:
              'We encountered a small hiccup while uploading your file. Your ideas are valuable - shall we give it another go?',
            duration: 5000
          })
        }
      }
    },
    [ctxProviderRef, toast, messageAttachments.length]
  )

  const handlePaste = useCallback(
    async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      if (messageAttachments.length >= MAX_FILES) {
        toast({
          title: 'Too many files',
          description: 'Maximum of 5 files allowed per message.',
          duration: 5000
        })
        return
      }

      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.kind === 'file') {
          event.preventDefault()
          const file = item.getAsFile()
          if (file) {
            if (file.size > MAX_FILE_SIZE) {
              toast({
                title: 'File too large',
                description: `${file.name} is too large. Maximum size is 30MB.`,
                duration: 5000
              })
              continue
            }

            if (Object.keys(acceptedFileTypes).includes(file.type)) {
              await handleFileUpload(file)
            } else {
              toast({
                title: 'File type not supported',
                description: `${file.name} isn't a supported file type.`,
                duration: 5000
              })
            }
          }
        }
      }
    },
    [handleFileUpload, messageAttachments.length, toast]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      acceptedFiles.forEach(file => handleFileUpload(file))
    },
    [handleFileUpload]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        acceptedFiles.forEach(file => {
          // Check file extension for markdown files
          if (
            file.name.toLowerCase().endsWith('.md') ||
            file.name.toLowerCase().endsWith('.markdown')
          ) {
            handleFileUpload(file)
            return
          }

          // For other files, check MIME type
          if (Object.keys(acceptedFileTypes).includes(file.type)) {
            handleFileUpload(file)
          } else {
            toast({
              title: 'File type not supported',
              description: `${file.name} is not a supported file type.`,
              duration: 5000
            })
          }
        })
      },
      [handleFileUpload, toast]
    ),
    noClick: true,
    noKeyboard: true,
    disabled: isUploadcareDialogOpen,
    accept: acceptedFileTypes,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILES,
    onDropRejected: (fileRejections: FileRejection[]) => {
      fileRejections.forEach(rejection => {
        const errorMessage = rejection.errors
          .map(error => {
            switch (error.code) {
              case 'file-too-large':
                return `${rejection.file.name} is too large. Maximum size is 30MB.`
              case 'too-many-files':
                return 'Too many files. Maximum is 5 files.'
              case 'file-invalid-type':
                return `${rejection.file.name} is not a supported file type.`
              default:
                return error.message
            }
          })
          .join(', ')

        toast({
          title: 'File upload error',
          description: errorMessage,
          duration: 5000
        })
      })
    }
  })

  const handleFileUploadSuccess = useCallback(
    (file: any) => {
      const attachmentData = JSON.parse(JSON.stringify(file))
      saveAttachment(attachmentData, anonymousUserId)
      setIsUploading(false)
      setMessageAttachments(prev => [...prev, attachmentData])
      setIsUploadcareDialogOpen(false)
    },
    [anonymousUserId, setMessageAttachments]
  )

  const handlePaperclipClick = useCallback(() => {
    const uploadCareAPI = ctxProviderRef.current?.getAPI()
    if (uploadCareAPI) {
      setIsUploadcareDialogOpen(true)
      uploadCareAPI.initFlow()
    }
  }, [ctxProviderRef])

  const handleActivityChange = useCallback(
    (event: CustomEvent<{ activity: string | null }>) => {
      // Consider both the main dialog and the file list view
      const isOpen =
        event.detail.activity !== null &&
        (event.detail.activity !== 'ready' ||
          document.querySelector('.uploadcare--files') !== null)
      setIsUploadcareDialogOpen(isOpen)
    },
    []
  )

  const handleRemoveMessageAttachment = useCallback(
    (index: number) => {
      const newAttachments = [...messageAttachments]
      newAttachments.splice(index, 1)
      setMessageAttachments(newAttachments)
    },
    [messageAttachments, setMessageAttachments]
  )

  useEffect(() => {
    const ctxProvider = ctxProviderRef.current
    if (ctxProvider) {
      ctxProvider.addEventListener('activity-change', handleActivityChange)
    }
    return () => {
      if (ctxProvider) {
        ctxProvider.removeEventListener('activity-change', handleActivityChange)
      }
    }
  }, [handleActivityChange])

  const models = useMemo(() => getAvailableModels(), [])
  const currentModel = useMemo(() => {
    return models.find(m => m.value === nexusState.requestedModel) || models[0]
  }, [models, nexusState.requestedModel])

  // Event listeners for dialog close and ESC key for uploadcare dialog
  useEffect(() => {
    const handleDialogClose = () => {
      setIsUploadcareDialogOpen(false)
    }

    // Listen for the ESC key
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isUploadcareDialogOpen) {
        handleDialogClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    // Also listen for the dialog's close button click
    document.addEventListener('dialog.close', handleDialogClose)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('dialog.close', handleDialogClose)
    }
  }, [isUploadcareDialogOpen])

  // Add focus/blur handlers
  const handleInputFocus = () => {
    setControlsExpanded(true)
  }

  const handleInputBlur = () => {
    if (hasMessages && input.trim() === '') {
      setControlsExpanded(false)
    }
  }

  // Add effect to manage controls visibility based on messages/input state
  useEffect(() => {
    // Always expanded when no messages or when typing
    if (!hasMessages || input.trim() !== '') {
      setControlsExpanded(true)
    } else if (isMobile && !inputRef.current?.matches(':focus')) {
      // On mobile, collapse when there are messages and input is not focused
      setControlsExpanded(false)
    }
  }, [hasMessages, input, isMobile, inputRef])

  return (
    <div
      id="request-form-container"
      {...getRootProps()}
      className={`bg-background p-4 dark:bg-dark-base ${
        isDragActive && !isUploadcareDialogOpen
          ? 'border-2 border-dashed border-primary bg-base-200'
          : ''
      }`}
    >
      {/* Follow-up questions */}
      <div className="hidden sm:block">
        <AnimatePresence>
          {showStarterQuestions && (
            <SuggestedQuestions
              questions={STARTER_QUESTIONS}
              onSelect={setInput}
              label="Explore with me..."
            />
          )}
          {showFollowUpQuestions && (
            <SuggestedQuestions
              questions={followUpQuestions}
              onSelect={setInput}
              label="Related questions..."
            />
          )}
        </AnimatePresence>
      </div>

      {/* Request input form - moved above attachments */}
      <div className="mx-auto flex flex-col rounded-lg transition-all duration-300">
        <form
          data-testid="request-input-form"
          ref={formRef}
          onSubmit={onSubmit}
          className="flex flex-col"
        >
          <Textarea
            ref={inputRef}
            data-testid="request-input-textarea"
            maxRows={10}
            onKeyDown={enterToSubmit && !isMobile ? onKeyDown : undefined}
            onPaste={handlePaste}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            rows={1}
            name="message"
            autoFocus
            spellCheck={false}
            autoComplete="off"
            placeholder="Your request..."
            value={input}
            onChange={e => setInput(e.target.value)}
            className="textarea textarea-bordered mb-2 min-h-12 w-full resize-none focus:border-primary focus:ring-1 focus:ring-primary"
            aria-label="Chat input"
          />

          {/* After the Textarea, where the control buttons start */}
          <div className="flex items-center">
            {/* Left side controls (attachments, model selector) */}
            <AnimatePresence>
              {controlsExpanded && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex items-center overflow-hidden"
                >
                  {/* Uploadcare styles and provider */}
                  <style type="text/css">{`
                    uc-copyright {
                      display: none !important;
                    }
                    uc-file-uploader-regular {
                      position: absolute;
                      width: 1px;
                      height: 1px;
                      padding: 0;
                      margin: -1px;
                      overflow: hidden;
                      clip: rect(0, 0, 0, 0);
                      white-space: nowrap;
                      border: 0;
                    }
                    // Prevent Uploadcare's drag and drop
                    .uploadcare--dialog__panel {
                      pointer-events: none;
                    }
                    .uploadcare--dialog__panel-content {
                      pointer-events: auto;
                    }
                  `}</style>
                  <uc-upload-ctx-provider
                    ref={ctxProviderRef}
                    ctx-name="Cora Conversation Attachments"
                  />
                  <FileUploaderRegular
                    ctxName="Cora Conversation Attachments"
                    multipleMax={MAX_FILES}
                    useCloudImageEditor={false}
                    multiple={true}
                    maxLocalFileSizeBytes={MAX_FILE_SIZE}
                    sourceList="local, camera, dropbox, gdrive, instagram"
                    classNameUploader="uc-light uc-purple"
                    pubkey={uploadcareClientConfig.publicKey || ''}
                    onFileUploadSuccess={handleFileUploadSuccess}
                    metadata={{
                      user_id: user?.emailAddresses[0]?.emailAddress || anonymousUserId || ''
                    }}
                    accept={`${Object.keys(acceptedFileTypes).join(',')},text/plain,.md,.markdown`}
                  />
                  <Button
                    variant="ghost"
                    className="btn m-2 font-medium"
                    type="button"
                    onClick={handlePaperclipClick}
                    aria-label="Attach files"
                  >
                    <Paperclip className="mr-2 h-5 w-5" />
                    <span className="hidden lg:inline">Attach</span>
                  </Button>

                  {/* Model selector dropdown */}
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        className="btn m-2 font-medium"
                        aria-label="Select AI model"
                      >
                        <Image
                          src={currentModel.logo}
                          alt="Model icon"
                          width={20}
                          height={20}
                          className="mr-2 rounded-sm"
                        />
                        <span className="hidden lg:inline">{currentModel.label}</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start" className={cn(dropdownStyles.content)}>
                      {models.map(model => (
                        <DropdownMenuItem
                          key={model.value}
                          className={cn(
                            'relative flex cursor-default select-none items-center rounded-sm px-2 py-2 text-sm outline-none transition-colors hover:bg-gray-100 dark:hover:bg-gray-700',
                            requestedModel === model.value && 'bg-gray-100 dark:bg-gray-700'
                          )}
                          onClick={() => {
                            setManualRequestedModel(model.value)
                            setRequestedModel(model.value)
                          }}
                        >
                          <div className="flex flex-1 items-center gap-2">
                            <Image
                              src={model.logo}
                              alt={model.label}
                              width={16}
                              height={16}
                              className="flex-shrink-0 rounded-sm"
                            />
                            <span className="truncate">{model.label}</span>
                          </div>
                          {requestedModel === model.value && (
                            <Check className="ml-2 h-4 w-4 flex-shrink-0" />
                          )}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Status indicators */}
            {conciergeStatus && (
              <>
                <RingLoader color="#4f46e5" loading size={20} />
                <div className="ml-2 animate-pulse text-sm text-[#4f46e5]">
                  {conciergeStatus}
                </div>
              </>
            )}

            {!conciergeStatus && aiStatus && (
              <div className="ml-2 animate-pulse text-sm text-gray-500">{aiStatus}</div>
            )}

            {/* Send button with toggle - always visible */}
            <div className="ml-auto flex items-center gap-2">
              <AnimatePresence>
                {controlsExpanded && !isMobile && (
                  <motion.div
                    initial={{ opacity: 0, width: 0 }}
                    animate={{ opacity: 1, width: 'auto' }}
                    exit={{ opacity: 0, width: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="flex items-center">
                            <Switch
                              checked={enterToSubmit}
                              onCheckedChange={setEnterToSubmit}
                              className="data-[state=checked]:bg-primary"
                              aria-label="Toggle enter to send"
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="top">
                          <p>Press Enter to send your message</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </motion.div>
                )}
              </AnimatePresence>

              <Button
                type="submit"
                className="btn btn-primary gap-1.5 transition-all duration-200 hover:bg-primary/90 active:scale-95"
                onClick={isLoading ? stop : undefined}
                aria-label={isLoading ? 'Stop generating' : 'Send message'}
                disabled={nexusState.conciergeLoading}
              >
                <span className="hidden sm:inline">{isLoading ? 'Stop' : 'Send'}</span>
                {isLoading ? <Square size={20} /> : <Send size={20} />}
              </Button>
            </div>
          </div>
        </form>
      </div>

      {/* Attachments grid and loading indicator - moved below */}
      <AnimatePresence>
        {(messageAttachments.length > 0 || isUploading) && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4 overflow-hidden"
          >
            <div className="overflow-x-auto">
              <div className="flex space-x-2">
                {messageAttachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="flex min-w-[150px] items-center justify-between rounded-lg bg-base-200 p-2"
                  >
                    <div className="flex items-center space-x-2 overflow-hidden">
                      <AttachmentIcon attachment={attachment} width={24} height={24} />
                      <span className="truncate text-sm text-base-content">
                        {attachment.name}
                      </span>
                    </div>
                    <button
                      onClick={() => handleRemoveMessageAttachment(index)}
                      className="btn btn-ghost btn-xs ml-2 text-base-content hover:text-error"
                      aria-label={`Delete ${attachment.name}`}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
                {isUploading && (
                  <div className="flex min-w-[150px] items-center justify-center rounded-lg bg-base-200 p-2">
                    <Loader className="h-5 w-5 animate-spin text-base-content" />
                    <span className="ml-2 text-sm text-base-content">Uploading...</span>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Response Mode Slider - wrap in AnimatePresence */}
      <AnimatePresence>
        {controlsExpanded && !isLoading && !conciergeLoading && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4 w-full"
          >
            <div className="flex flex-col">
              {/* Slider with labels underneath */}
              <div className="relative">
                <Slider
                  value={[sliderValue]}
                  onValueChange={handleSliderChange}
                  min={0}
                  max={2}
                  step={1}
                  className="w-full mb-2"
                />
                <div className="flex w-full justify-between px-1">
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => handleSliderChange([0])}
                          className="flex items-center gap-2 text-center text-sm text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
                        >
                          <Zap className="h-4 w-4" />
                          <span>Swift</span>
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-[200px]">
                        <p>
                          Quick responses optimized for speed. Best for simple questions and
                          real-time interactions.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => handleSliderChange([1])}
                          className="flex items-center gap-2 text-center text-sm text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
                        >
                          <Scale className="h-4 w-4" />
                          <span>Balanced</span>
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-[200px]">
                        <p>
                          Optimal balance between speed and depth. Recommended for most
                          conversations and general questions.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => handleSliderChange([2])}
                          className="flex items-center gap-2 text-center text-sm text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
                        >
                          <Brain className="h-4 w-4" />
                          <span>Deep</span>
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-[200px]">
                        <p>
                          Thorough analysis with advanced reasoning. Perfect for complex
                          problems and detailed explanations.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
