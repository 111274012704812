import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/nextjs'
import { motion } from 'framer-motion'
import { LogIn } from 'lucide-react'

import { NexusState } from '@/app/lib/types'

export function UserProfile({ nexusState }: { nexusState: NexusState }) {
  const { isUserLoaded, user } = nexusState

  if (!isUserLoaded) {
    return <div className="flex gap-2"></div>
  }

  return (
    <div className="flex gap-2 text-base-content">
      <SignedIn>
        <UserButton
          appearance={{
            elements: {
              userButtonBox: 'text-base-content'
            }
          }}
        />
      </SignedIn>
      <SignedOut>
        <SignInButton mode="modal">
          <motion.button
            className="btn btn-primary gap-1.5 transition-all duration-200 hover:bg-primary/90 active:scale-95"
            animate={{
              scale: [1, 1.02, 1],
              boxShadow: [
                '0 0 0 0 rgba(79, 70, 229, 0)',
                '0 0 0 4px rgba(79, 70, 229, 0.2)',
                '0 0 0 0 rgba(79, 70, 229, 0)'
              ]
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'reverse'
            }}
          >
            <LogIn className="h-5 w-5" />
            <span className="hidden sm:inline">Login / SignUp</span>
            <span className="sm:hidden">Sign in</span>
          </motion.button>
        </SignInButton>
      </SignedOut>
    </div>
  )
}
